import React  from "react";
import { useSelector } from "react-redux";

export default function Disclaimer(props) {
  
  const languageData = useSelector((state) => state.languageData);
  const language = useSelector((state) => state.language);
  
  return (
    <div className="introduction">
      <p>
        {/* A small word game inspired by <br />
        Scrabble® and Wordle® */}
        {languageData.map(function (d) {
          return d.claim[language];
        })}
      </p>
      <p>
        <small>Pre-Alpha - Edition, 2023 © Pierre Lippuner</small>
      </p>
    </div>
  );
}
