export const FIELD_TYPE = {
  None: Symbol("none"),
  Start: Symbol("start"),
  DoubleLetter: Symbol("doubleLetter"),
  TripleLetter: Symbol("tripleLetter"),
  DoubleWord: Symbol("doubleWord"),
  TripleWord: Symbol("tripleWord"),
  HalfLetter: Symbol("halfLetter"),
  HalfWord: Symbol("halfWord"),
  ZeroLetter: Symbol("zeroLetter"),
  ZeroWord: Symbol("zeroWord"),
  Blocked: Symbol("blocked")
};
export const fieldMap = {
  "none": FIELD_TYPE.None,
  "start": FIELD_TYPE.Start,
  "doubleLetter": FIELD_TYPE.DoubleLetter,
  "tripleLetter": FIELD_TYPE.TripleLetter,
  "doubleWord": FIELD_TYPE.DoubleWord,
  "tripleWord": FIELD_TYPE.TripleLetter,
  "halfLetter": FIELD_TYPE.HalfLetter,
  "halfWord": FIELD_TYPE.HalfWord,
  "zeroLetter": FIELD_TYPE.ZeroLetter,
  "zeroWord": FIELD_TYPE.ZeroWord,
  "blocked": FIELD_TYPE.Blocked,
};

export const UNCONNECTED = 999;