
  export let gamePieceTemplate = {
    letter: "",
    value: "",
    probability: -1,
    parent: null,
    x: -1,
    y: -1,
    isWord: true,
    wordX: true,
    wordY: true,
    prevX: null,
    nextX: null,
    prevY: null,
    nextY: null,
    manhattenDist: Number.MAX_SAFE_INTEGER,
    startX: null,
    startY: null,
    startVal: 999,
    fullWord: false,
    fullGame: false,
  };
  
  export let wordTemplate = {
    string: "",
    pieces: [],
    connections: [],
    wordDist: Number.MAX_SAFE_INTEGER,
    viable: false,
    valid: false,
  };
  
  export let saveTemplate = {
    session: "",
    time: -1,
    timeLeft: -1,
    pieces: "",
    finished: false,
    infiniteMode: false,
    fields: [["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],],
    points: "?",
  }