import React, { useState } from "react";
import { useDraggable } from "@dnd-kit/core";
import { UNCONNECTED } from "../constants/fieldTypes";

export function Draggable(props) {
  const { attributes, listeners, isDragging, setNodeRef, transform } =
    useDraggable({
      id: props.id,
      value: props.value,
      letter: props.letter,
      parent: props.parent,
      isword: props.isword,
      disabled: props.disabled,
      state: props.state,
      data: {
        letter: props.letter,
        value: props.value,
        parent: props.parent,
        index: props.index,
        x: props.x,
        y: props.y,
        correct: props.correct,
      },
    });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  function SetClassName() {
    let className = "piece";

    className += " "+ props.state;
    if (props.manhattendist === UNCONNECTED) className += " unconnected";
    if (!props.isword && props.parent !== null) className += " wrong";
    if (props.isword) className += " correct";
    if (props.fulllength) className += " full-length";
    if (props.fullgame) className += " full-game";

    return className;
  }

  return (
    <div
      className={SetClassName()}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      {...props}
      // {...transform}
      // {...isDragging}
    >
      {/* <span className="debug">{props.fulllength}</span> */}
      <div className="content">
        {props.children}
        <span className="value">{props.value}</span>
        <span className="piece-overlay"></span>
      </div>
    </div>
  );
}
