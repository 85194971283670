export let allVowelsEnglish = [
    {
      letter: "A",
      value: "1",
      probability: 9,
    },
    {
      letter: "E",
      value: "1",
      probability: 12,
    },
    {
      letter: "I",
      value: "1",
      probability: 9,
    },
    {
      letter: "O",
      value: "1",
      probability: 8,
    },
    {
      letter: "U",
      value: "1",
      probability: 4,
    },
    {
      letter: "Y",
      value: "4",
      probability: 2,
    },
  ];
  
  export let allOthersEnglish = [
    {
      letter: "B",
      value: "3",
      probability: 2,
    },
    {
      letter: "C",
      value: "3",
      probability: 2,
    },
    {
      letter: "D",
      value: "2",
      probability: 4,
    },
    {
      letter: "F",
      value: "4",
      probability: 2,
    },
    {
      letter: "G",
      value: "2",
      probability: 3,
    },
    {
      letter: "H",
      value: "4",
      probability: 2,
    },
    {
      letter: "J",
      value: "8",
      probability: 1,
    },
    {
      letter: "K",
      value: "5",
      probability: 1,
    },
    {
      letter: "L",
      value: "1",
      probability: 4,
    },
    {
      letter: "M",
      value: "3",
      probability: 2,
    },
    {
      letter: "N",
      value: "1",
      probability: 6,
    },
    {
      letter: "P",
      value: "2",
      probability: 3,
    },
    {
      letter: "Q",
      value: "10",
      probability: 1,
    },
    {
      letter: "R",
      value: "1",
      probability: 6,
    },
    {
      letter: "S",
      value: "1",
      probability: 4,
    },
    {
      letter: "T",
      value: "1",
      probability: 6,
    },
    {
      letter: "V",
      value: "4",
      probability: 2,
    },
    {
      letter: "W",
      value: "4",
      probability: 2,
    },
    {
      letter: "X",
      value: "8",
      probability: 1,
    },
    {
      letter: "Z",
      value: "10",
      probability: 1,
    },
  ];