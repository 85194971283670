import React, { useState, useEffect } from "react";
import data from "../lexijam.json";
import { useSelector, useDispatch } from "react-redux";

export default function Disclaimer(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    var allowedLanguages = ["en-EN", "de-DE"];
    var userLang = navigator.language || navigator.userLanguage;
    if(allowedLanguages.includes(userLang) == false) {
      userLang = "en-EN";
    }
    
    dispatch({ type: "SET_LANGUAGE_DATA", payload: data });
    dispatch({ type: "SET_LANGUAGE", payload: userLang });
  }, []);

  return <span></span>;
}
