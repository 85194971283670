import React, { useState } from "react";

const ExitGame = ({ endGameCallback, active }) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const handleExitClick = () => {
    setIsOverlayOpen(true);
  };

  const handleCloseOverlay = () => {
    setIsOverlayOpen(false);
  };

  const handleEndGame = () => {
    setIsOverlayOpen(false);
    endGameCallback(); // Call the callback function to end the game in the parent component
  };

  return (
    <div className="exit-overlay">
      {active && <a className="button" onClick={handleExitClick}>Exit Game</a>}
      {isOverlayOpen && (
        <div className="overlay exit">
          <p>Are you sure you want to exit the game?</p>
          <a className="button" onClick={handleCloseOverlay}>Cancel</a>
          <a className="button" onClick={handleEndGame}>End Game</a>
        </div>
      )}
    </div>
  );
};

export default ExitGame;
