import React from "react";
import { ReactComponent as SVG } from "../lexijam.svg";

export default function Logo(props) {
  return (
    <div className="logo-header">
      <span className="smallFlag">{props.children}</span>
      <SVG></SVG>
      <span className="smallFlag">{props.children}</span>
    </div>
  );
}
