import { Outlet, Link, useLocation } from "react-router-dom";
import Disclaimer from "../components/Disclaimer";
import Logo from "../components/Logo";
import { useSelector, useDispatch } from "react-redux";

var userLang = navigator.language || navigator.userLanguage;

const Layout = () => {
  const location = useLocation();
  const languageData = useSelector((state) => state.languageData);
  const language = useSelector((state) => state.language);

  return (
    <>
      {location.pathname === "/" ? (
        <nav className="startscreen">
          <Logo />

          <div>
            <Disclaimer />
          </div>

          <ul className="nav">
            <li>
              <Link className="button" to="/de">
                <span className="smallFlag left">🇩🇪</span>
                Deutsch
              </Link>
            </li>
            <li>
              <Link className="button" to="/en">
                <span className="smallFlag left">🇬🇧</span>
                English
              </Link>
            </li>
            <div className="divider"></div>
            <li>
              <Link className="button" to="/archive">
                Archiv              
                </Link>
            </li>
            <li>
              <Link className="button" to="/instructions">
                {/* How to Play */}
                {
                languageData.map(function (d) {
                  return d.playPrompt[language];
                })}
              </Link>
            </li>
          </ul>
          <div>
            <small className="white">This page uses Google analytics</small>
          </div>
        </nav>
      ) : (
        ""
      )}

      <Outlet />
    </>
  );
};

export default Layout;
