import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { parse, stringify, toJSON, fromJSON } from "flatted";

export function Unload(props) {
  const timer = useSelector((state) => state.timer);
  const currentSession = useSelector((state) => state.currentSession);
  // const time = useSelector((state) => state.time);

  useEffect(() => {
    window.onunload = function () {
      var now = new Date().getTime();
      var timeLeft = timer - now;
      var seconds = timeLeft / 1000;
      var timeToPlay = Math.round(seconds);

      console.log("seconds is " + seconds);
      // console.log("Duration to play is " + time);
      console.log("Time to play is " + timeToPlay);
      console.log("start Time" + timer);
      console.log("current Time" + new Date().getTime());
      // localStorage.setItem("timeLeft", timeToPlay);

      // Load savedGames from localStorage or initialize an empty array if it doesn't exist
      var savedGames = parse(localStorage.getItem("savedGames")) || [];

      // Find the index of the savedGame with the same session as currentGame
      var indexToReplace = savedGames.findIndex(
        (save) => save.session === currentSession
      );
      
      var currentGame = savedGames[indexToReplace];

      // // If a matching savedGame was found, replace it
      // if (indexToReplace !== -1) {
      //   savedGames[indexToReplace] = currentGame;
      // } else {
      //   // If no matching savedGame was found, add the currentGame
      //   savedGames.push(currentGame);
      // }
      
      currentGame.timeLeft = timeToPlay;

      // Remove all games with the session ID of currentGame
      savedGames = savedGames.filter(
        (save) => save.session !== currentSession
      );

      savedGames.push(currentGame);

      // Save the pruned array back to localStorage
      localStorage.setItem("savedGames", stringify(savedGames));
    };

    return () => {
      window.onunload = null;
    };
  }, []);

  return <div className="unload"></div>;
}
