import React, { useState, useEffect } from "react";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { RWebShare } from "react-web-share";
import { useSelector, useDispatch } from "react-redux";

import { Droppable } from "./components/Droppable";
import { Draggable } from "./components/Draggable";
import { Points } from "./components/Points";
import { Unload } from "./components/Unload";
import { CountdownTimer } from "./components/CountdownTimer";
import { FIELD_TYPE, UNCONNECTED } from "./constants/fieldTypes";
import {
  wordTemplate,
  gamePieceTemplate,
  saveTemplate,
} from "./constants/templates";
// import { allOthers, allVowels } from "./constants/germanLetterSet";

import Chance from "chance";

import ExitGame from "./components/ExitGame";
import Disclaimer from "./components/Disclaimer";
import Logo from "./components/Logo";
import { parse, stringify, toJSON, fromJSON } from "flatted";
import { Outlet, Link, useLocation } from "react-router-dom";
// import dict from "./words_DE_short.txt";

// TODO: Precise: When is a word correct, when is it false
// Render Order: Wrong < Correct < Full Line < Full Game (Maybe just design the damn thing)
// Multiple multipliers not possible (double TW for example)

// GAME STATE
let sessionPlayed = false;
let interuptedSession = false;

// GAME SETTINGS
const duration = 90;
const fullLengthBonus = 10;
const fullGameBonus = 25;
let numberOfPieces = 14;
let numOfFields = 7;
let MaxSpecialFields = 3;
let minVowels = 3;
let maxVowels = 6;
let numVowels = 0;

let gamePieces = [];

let wordDict = "";
let specialFields = [
  ["", "", "", "", "", "", ""],
  ["", "", "", "", "", "", ""],
  ["", "", "", "", "", "", ""],
  ["", "", "", "", "", "", ""],
  ["", "", "", "", "", "", ""],
  ["", "", "", "", "", "", ""],
  ["", "", "", "", "", "", ""],
];

let debugFields = [
  ["", "", "", FIELD_TYPE.ZeroWord, "", "", ""],
  ["", "", "", "", "", "", ""],
  ["", "", "", FIELD_TYPE.ZeroLetter, FIELD_TYPE.TripleLetter, "", ""],
  ["", "", "", "", "", "", ""],
  ["", "", "", "", "", "", ""],
  ["", "", "", "", "", "", ""],
  ["", "", "", "", "", "", ""],
];

// SHARING
let shareBoard = [
  ["", "", "", "", "", "", ""],
  ["", "", "", "", "", "", ""],
  ["", "", "", "", "", "", ""],
  ["", "", "", "", "", "", ""],
  ["", "", "", "", "", "", ""],
  ["", "", "", "", "", "", ""],
  ["", "", "", "", "", "", ""],
];
let shareTile = "🟡";
let shareField = "⚪";

let timeDesc = "";
let currentSession = "";
let currentGame;
let savedGames = [];
let languageFlag = "";
let infiniteMode = false;

export default function App(props) {
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const dispatch = useDispatch();

  const timeLeft = useSelector((state) => state.time);
  const languageData = useSelector((state) => state.languageData);
  const language = useSelector((state) => state.language);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const [started, setStart] = useState(false);
  const [initialTime, setInitialTime] = useState(duration);
  const [shareDescription, setDesc] = useState("Test");

  const [awake, setAwake] = useState("");
  const [points, setPoints] = useState(0);
  const xCon = ["A", "B", "C", "D", "E", "F", "G"];
  const yCol = ["row1", "row2", "row3", "row4", "row5", "row6", "row7"];
  let [game, setGame] = useState([
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
    ["", "", "", "", "", "", ""],
  ]);

  const [pieces, setPieces] = useState(gamePieces);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  // INIT / SETUP
  useEffect(() => {
    var language = props.language;

    languageFlag = language === "de" ? "🇩🇪" : "🇬🇧";
    // Setup Seed
    var session = params.get("session");
    const date = new Date();
    const coeff = 1000 * 60 * 60;

    if (session === null) {
      session = JSON.stringify(
        new Date(Math.floor(date.getTime() / coeff) * coeff)
      );
    }

    session = language + "-" + session;
    currentSession = session;
    currentGame = { ...saveTemplate };
    currentGame.session = currentSession;

    console.log("Current session is " + currentGame.session);

    var thisDate = new Date();
    var day = thisDate.getDate();
    var month = thisDate.getMonth() + 1;
    var year = thisDate.getFullYear().toString().substr(-2);
    var hour = thisDate.getHours();

    timeDesc = "" + day + "." + month + "." + year + " " + hour + ":00";
    
    console.log(timeDesc);

    var saveGame = false;

    var savedTime = Number.MAX_SAFE_INTEGER;
    // savedGames = null;
    var checkSaveGame = localStorage.getItem("savedGames");

    if (checkSaveGame !== null) savedGames = parse(checkSaveGame);

    if (savedGames !== null) {
      // Remove null objects from the savedGames array
      savedGames = savedGames.filter((save) => save !== null);

      var savedGame = savedGames.find(
        (save) => save.session === currentGame.session
      );

      // console.log(savedGames[0].session);
      if (savedGame) {
        console.log("Game already exists");
        currentGame = savedGame;
      }

      interuptedSession =
        savedGame && savedGame.finished == false && savedGame.timeLeft != null;

      if (savedGame) {
        savedTime = parseInt(savedGame.time);
        saveGame = savedGame.finished;
      } else {
        currentGame.finished = false;
        currentGame.time = Number.MAX_SAFE_INTEGER;
        saveGame = false;
      }

      sessionPlayed = savedGame && saveGame == true;
    } else sessionPlayed = false;

    require(["seedrandom"], function (seedrandom) {
      gamePieces = [];
      var seed = awake;

      if (awake === "") seed = session;

      var chance1 = new Chance(session);

      var vowels = [];
      var consonants = [];

      props.vowels.forEach((vow) => {
        var num = vow.probability;

        for (var i = 0; i < num; i++) {
          var p = { ...gamePieceTemplate };
          p.letter = vow.letter;
          p.value = vow.value;
          p.probability = vow.probability;

          vowels.push(p);
        }
      });

      props.consonants.forEach((con) => {
        var num = con.probability;

        for (var i = 0; i < num; i++) {
          var p = { ...gamePieceTemplate };
          p.letter = con.letter;
          p.value = con.value;
          p.probability = con.probability;

          consonants.push(p);
        }
      });

      numVowels = chance1.integer({ min: minVowels, max: maxVowels });

      for (var i = 0; i < numberOfPieces; i++) {
        var r = chance1.integer({ min: 0, max: consonants.length - 1 });

        if (i < numVowels) {
          r = chance1.integer({ min: 0, max: vowels.length - 1 });
          gamePieces.push({ ...vowels[r] });
          vowels.splice(r, 1);
        } else {
          gamePieces.push({ ...consonants[r] });
          consonants.splice(r, 1);
        }
      }

      let shuffledPieces = chance1.shuffle(gamePieces);

      var debugBoard = params.get("debug");

      if (debugBoard === "true") {
        specialFields = debugFields;

        specialFields[3][3] = FIELD_TYPE.Start;
        var numOfSpecialFields = chance1.integer({
          min: 0,
          max: MaxSpecialFields,
        });
      } else {
        // Deep Clone Special Fields
        specialFields = game.map((x) => {
          return { ...x };
        });

        specialFields[3][3] = FIELD_TYPE.Start;
        var numOfSpecialFields = chance1.integer({
          min: 0,
          max: MaxSpecialFields,
        });

        for (var n = 0; n < numOfSpecialFields; n++) {
          var sf = [
            FIELD_TYPE.DoubleLetter,
            FIELD_TYPE.DoubleLetter,
            FIELD_TYPE.DoubleLetter,
            FIELD_TYPE.DoubleLetter,
            FIELD_TYPE.HalfLetter,
            FIELD_TYPE.HalfLetter,
            FIELD_TYPE.TripleLetter,
            FIELD_TYPE.ZeroLetter,
            FIELD_TYPE.DoubleWord,
            FIELD_TYPE.DoubleWord,
            FIELD_TYPE.HalfWord,
            FIELD_TYPE.HalfWord,
            FIELD_TYPE.TripleWord,
            FIELD_TYPE.ZeroWord,
            FIELD_TYPE.Blocked,
            FIELD_TYPE.Blocked,
            FIELD_TYPE.Blocked,
          ];
          var randomIndex = chance1.integer({ min: 0, max: sf.length - 1 });
          var x = chance1.integer({ min: 0, max: specialFields.length - 1 });
          var y = chance1.integer({ min: 0, max: specialFields.length - 1 });

          if (x === 3 && y === 3) {
            numOfSpecialFields++;
            continue;
          }
          specialFields[x][y] = sf[randomIndex];
        }
      }
      
      console.log(specialFields);
      
      var testFields = specialFields;
      
      for(var x = 0; x < testFields.length; x++){
        for(var y = 0; y < testFields.length; y++){
          if(testFields[x][y] !== ""){
            console.log(x, y, testFields[x][y]);
            currentGame.fields[x][y] = testFields[x][y].toString();
            console.log(currentGame.fields[x][y]);
          }
            // console.log(testFields[x][y]);
        }
      }
      
      // currentGame.fields = specialFields;
      
      setPieces(shuffledPieces);

      // Setup dictionary
      fetch(props.dictionary)
        .then((r) => r.text())
        .then((text) => {
          var str = text.toUpperCase(); // Convert to uppercase, so the game can compare them
          wordDict = str.split(/\r?\n|\r|\n/g); // Split by line break to array
        });

      // Start Game anyways if time Left is true
      if (interuptedSession && !sessionPlayed) toggleGame(currentGame.infinite);
    });
  }, [awake]);

  const toggleGame = (infinite) => {
    infiniteMode = infinite;

    console.log("Infinity mode " + infiniteMode);
    
    var dur = duration;
    var time = params.get("time");
    var savedtimeLeft = currentGame.timeLeft;
    
    setIsGameEnded(false);
    
    if (time !== null) dur = time;
    if (savedtimeLeft !== -1) dur = savedtimeLeft;
    
    var t = new Date().getTime() + dur * 1000;
    
    currentGame.infinite = infiniteMode;
    currentGame.time = t;
    currentGame.finished = false;

    if (interuptedSession && !sessionPlayed) {
      var savedGamePieces = currentGame.pieces;
      if (currentGame !== null) {
        if (currentGame.session === currentSession) {
          setPieces(savedGamePieces);
        }
      }
    }

    setStart(true);
    setInitialTime(t);

    console.log("Duration is " + dur);
    dispatch({ type: "SET_TIME", payload: dur });
    dispatch({ type: "SET_PIECES", payload: pieces });
    dispatch({ type: "SET_GAME", payload: currentGame.session });

    dispatch({ type: "SET_TIMER", payload: new Date().getTime() + dur * 1000 });
  };

  // UPDATE
  useEffect(() => {
    let gameCurr = [...game];

    // Empty board temporarly
    for (var x = 0; x < xCon.length; x++) {
      for (var y = 0; y < yCol.length; y++) {
        gameCurr[x][y] = "";
        shareBoard[x][y] = shareField;
      }
    }

    // Assign letters
    for (var i = 0; i < pieces.length; i++) {
      if (pieces[i].parent !== null) {
        let x = pieces[i].x;
        let y = pieces[i].y;

        gameCurr[x][y] = pieces[i];
        gameCurr[x][y].x = x;
        gameCurr[x][y].y = y;

        // pieces.x = x;
        // pieces.y = y;
      }
    }

    // Setup Neighbours
    for (var y = 0; y < gameCurr.length; y++) {
      for (var x = 0; x < gameCurr.length; x++) {
        if (gameCurr[x][y] !== "") {
          gameCurr[x][y].manhattenDist = UNCONNECTED;
          gameCurr[x][y].prevX = "";
          gameCurr[x][y].nextX = "";
          gameCurr[x][y].prevY = "";
          gameCurr[x][y].nextY = "";

          if (x > 0) gameCurr[x][y].prevX = gameCurr[x - 1][y];
          if (x < gameCurr.length - 1)
            gameCurr[x][y].nextX = gameCurr[x + 1][y];
          if (y > 0) gameCurr[x][y].prevY = gameCurr[x][y - 1];
          if (y < gameCurr.length - 1)
            gameCurr[x][y].nextY = gameCurr[x][y + 1];
        }
      }
    }

    // Setup Manhatten Distance
    let startX = 3;
    let startY = 3;

    if (gameCurr[startX][startY] === "") {
      // console.warn("Start field not covered");
    } else {
      let start = gameCurr[startX][startY];
      start.manhattenDist = 0;

      SetManhattenDist(start);
    }

    setGame(gameCurr);

    let horizontalWords = [];
    let verticalWords = [];

    var hWords = [];
    var vWords = [];

    // Check for "words" horizontally
    for (var y = 0; y < yCol.length; y++) {
      let word = [];
      for (var x = 0; x < xCon.length; x++) {
        if (gameCurr[x][y] !== "") {
          // RESET ALL VALUES
          gameCurr[x][y].startX = null;
          gameCurr[x][y].startY = null;
          gameCurr[x][y].startVal = UNCONNECTED;
          gameCurr[x][y].wordX = true;
          gameCurr[x][y].wordY = true;
          gameCurr[x][y].isWord = true;
          // Check if new Word
          if (x > 0) {
            if (gameCurr[x - 1][y] === "") {
              if (word.length > 1) {
                horizontalWords.push(word);
                hWords.push(CreateWord(word));
              }

              word = [];
            }
          }

          word.push(gameCurr[x][y]);
        }
      }

      if (word.length > 1) {
        horizontalWords.push(word);
        hWords.push(CreateWord(word));
      }
    }

    // Check for "words" vertically
    for (var x = 0; x < xCon.length; x++) {
      let word = [];
      for (var y = 0; y < yCol.length; y++) {
        if (gameCurr[x][y] !== "") {
          gameCurr[x][y].startX = null;
          gameCurr[x][y].startY = null;
          gameCurr[x][y].startVal = UNCONNECTED;
          gameCurr[x][y].wordX = true;
          gameCurr[x][y].wordY = true;
          gameCurr[x][y].isWord = true;
          // Check if new Word
          if (y > 0) {
            if (gameCurr[x][y - 1] === "") {
              if (word.length > 1) {
                verticalWords.push(word);
                vWords.push(CreateWord(word));
              }

              word = [];
            }
          }

          word.push(gameCurr[x][y]);
        }
      }

      if (word.length > 1) {
        verticalWords.push(word);
        vWords.push(CreateWord(word));
      }
    }

    var validWords = [];
    var validHorizontalWords = [];
    var validVerticalWords = [];
    var pointValue = 0;

    // Check Dictionary for Horizontal Words
    horizontalWords.forEach((word) => {
      var closest = word.reduce(function (prev, curr) {
        return prev.manhattenDist < curr.manhattenDist ? prev : curr;
      });

      word.forEach((letter) => {
        letter.startX = closest;
      });

      if (CheckWord(word, true)) {
        validWords.push(word);
        // validHorizontalWords.push(CreateWord(word));
      }
    });

    // Check Dictionary for Vertical Words
    verticalWords.forEach((word) => {
      var closest = word.reduce(function (prev, curr) {
        return prev.manhattenDist < curr.manhattenDist ? prev : curr;
      });

      word.forEach((letter) => {
        letter.startY = closest;
      });

      if (CheckWord(word, false)) {
        validWords.push(word);
        // validVerticalWords.push(CreateWord(word));
      }
    });

    // DEBUG
    hWords.forEach((horizontalWord) => {
      horizontalWord.connections = [];
      horizontalWord.valid = CheckWord(horizontalWord.pieces, true);
    });
    vWords.forEach((verticalWord) => {
      verticalWord.connections = [];
      verticalWord.valid = CheckWord(verticalWord.pieces, false);
    });

    hWords.forEach((horizontalWord) => {
      vWords.forEach((verticalWord) => {
        horizontalWord.pieces.forEach((letter) => {
          if (verticalWord.pieces.length > 0)
            if (verticalWord.pieces.includes(letter)) {
              horizontalWord.connections.push(verticalWord);
              verticalWord.connections.push(horizontalWord);
            }
        });
      });
    });

    // !DEBUG

    var fullGame = true;

    // Set points for full usage of Pieces
    // Loop through the actual pieces and check if any of the variables is not correct. This means they are not part of a "full game"
    pieces.forEach((p) => {
      if (
        p.parent === null ||
        p.wordX === false ||
        p.wordY === false ||
        p.manhattenDist === UNCONNECTED
      )
        fullGame = false;
    });

    if (fullGame) {
      pointValue += fullGameBonus;
    }

    pieces.forEach((p) => {
      p.fullGame = fullGame;

      if (p.startX && p.startY) {
        var vals = [p.startX.manhattenDist, p.startY.manhattenDist];
        p.startVal = Math.min(...vals);
      } else if (p.startX) {
        p.startVal = p.startX.manhattenDist;
      } else if (p.startY) {
        p.startVal = p.startY.manhattenDist;
      }
    });

    // validWords.sort((a, b) => a.startVal - a.startVal); // b - a for reverse sort

    var viableWords = hWords.concat(vWords);
    viableWords.sort((a, b) => a.wordDist - b.wordDist); // b - a for reverse sort

    // Award points regarding words and special fields
    viableWords.forEach((word) => {
      pointValue += AwardPointsV2(word, specialFields);
    });

    // Update Share Description
    SetupShareMessageV2(viableWords, shareBoard);
    ///setDesc(shareDesc);

    setPoints(pointValue);
    currentGame.points = pointValue;
  }, [pieces]);

  function SetupShareMessage(validWords, shareBoard) {
    console.log(validWords.length);
    validWords.forEach((word) => {
      var desc = "";
      for (var i = 0; i < word.length; i++) {
        var w = word[i];
        var x = w.x;
        var y = w.y;

        if (w.isWord === true) shareBoard[x][y] = shareTile;
      }

      //shareDescription = "";

      for (var y = 0; y < shareBoard.length; y++) {
        for (var x = 0; x < shareBoard.length; x++) {
          desc += shareBoard[x][y];

          if (x == 6) desc += "\n";
        }
      }

      setDesc(desc);
    });
  }

  function SetupShareMessageV2(viableWords, shareBoard) {
    // console.log(viableWords.length);
    viableWords.forEach((word) => {
      var desc = "";
      // console.log(word.length);
      for (var i = 0; i < word.pieces.length; i++) {
        var w = word.pieces[i];
        var x = w.x;
        var y = w.y;

        if (w.isWord === true) shareBoard[x][y] = shareTile;
      }

      //shareDescription = "";

      for (var y = 0; y < shareBoard.length; y++) {
        for (var x = 0; x < shareBoard.length; x++) {
          // console.log("shareBoard" + shareBoard[x][y]);
          desc += shareBoard[x][y];

          if (x == 6) desc += "\n";
        }
      }

      setDesc(desc);
    });
  }

  function CreateWord(word) {
    var wordPiece = { ...wordTemplate };
    var string = "";

    word.forEach((piece) => {
      string += piece.letter;
    });

    wordPiece.string = string;
    wordPiece.pieces = word;

    var minDist = Math.min(...word.map((item) => item.manhattenDist));
    wordPiece.wordDist = minDist;

    return wordPiece;
  }

  function CheckWord(word, horizontally) {
    var str = "";

    for (var i = 0; i < word.length; i++) {
      var w = word[i];

      if (w.manhattenDist === UNCONNECTED) continue;

      str += w.letter;
    }

    if (wordDict.includes(str)) {
      word.forEach((p) => {
        // p.isWord = isWord;
        if (horizontally) p.wordX = true;
        else p.wordY = true;
      });
      return true;
    } else {
      // isWord = false;
      word.forEach((p) => {
        p.isWord = false;
        if (horizontally) p.wordX = false;
        else p.wordY = false;
      });

      if (str.length > 0) console.log(str + " is NOT a word");
      return false;
    }
  }

  function AwardPoints(word, fields) {
    var pointValue = 0;
    var val = 0;
    var isWord = true;

    word.forEach((p) => {
      // Check if Letter was already marked as wrong >> This is important, so the calculation if a word with a higher priority takes place
      isWord = p.wordX && p.wordY ? true : false;
    });

    // Check Neighbours of intersections
    var cX = word[0].startX;
    var cY = word[0].startY;

    if (cX !== null) {
      var md = cX.startVal;

      // if (cX.wordX === false) isWord = false;
      if (cX.prevX !== "")
        if (cX.prevX.startVal < md && cX.prevX.isWord === false) isWord = false;
      if (cX.nextX !== "")
        if (cX.nextX.startVal < md && cX.nextX.isWord === false) isWord = false;
      if (cX.prevY !== "")
        if (cX.prevY.startVal < md && cX.prevY.isWord === false) isWord = false;
      if (cX.nextY !== "")
        if (cX.nextY.startVal < md && cX.nextY.isWord === false) isWord = false;
    }

    if (cY !== null) {
      var md = cY.startVal;
      // if (cY.wordY === false) isWord = false;
      if (cY.prevX !== "")
        if (cY.prevX.startVal < md && cY.prevX.isWord === false) isWord = false;
      if (cY.nextX !== "")
        if (cY.nextX.startVal < md && cY.nextX.isWord === false) isWord = false;
      if (cY.prevY !== "")
        if (cY.prevY.startVal < md && cY.prevY.isWord === false) isWord = false;
      if (cY.nextY !== "")
        if (cY.nextY.startVal < md && cY.nextY.isWord === false) isWord = false;
    }

    word.forEach((p) => {
      // Check if Letter was already marked as wrong
      p.isWord = isWord;
    });

    if (isWord === false) return pointValue;

    var fullLength = word.length > 6 ? true : false;

    var wordModifiers = [];

    word.forEach((p) => {
      var halfL = false;
      var doubleL = false;
      var tripleL = false;
      var zeroL = false;
      // Check for special fields
      var x = p.x;
      var y = p.y;
      if (fields[x][y] === FIELD_TYPE.DoubleWord)
        wordModifiers.push(FIELD_TYPE.DoubleWord); //doubleW = true;
      if (fields[x][y] === FIELD_TYPE.TripleWord)
        wordModifiers.push(FIELD_TYPE.TripleWord); //;
      if (fields[x][y] === FIELD_TYPE.HalfWord)
        wordModifiers.push(FIELD_TYPE.HalfWord); //;
      if (fields[x][y] === FIELD_TYPE.ZeroWord)
        wordModifiers.push(FIELD_TYPE.ZeroWord); //;
      if (fields[x][y] === FIELD_TYPE.HalfLetter) halfL = true;
      if (fields[x][y] === FIELD_TYPE.DoubleLetter) doubleL = true;
      if (fields[x][y] === FIELD_TYPE.TripleLetter) tripleL = true;
      if (fields[x][y] === FIELD_TYPE.ZeroLetter) zeroL = true;

      fullLength = p.fullLength == true ? true : fullLength;
      game[p.x][p.y].fullWord = fullLength;

      // Check Pointvalue
      var letterValue = parseInt(p.value);
      letterValue *= halfL ? 0.5 : 1;
      letterValue *= doubleL ? 2 : 1;
      letterValue *= tripleL ? 3 : 1;
      letterValue *= zeroL ? 0 : 1;
      val += letterValue;
    });

    // if (doubleW) val *= 2;
    // if (tripleW) val *= 3;
    var modifiedValue = val;

    wordModifiers.forEach((m) => {
      if (m == FIELD_TYPE.DoubleWord) modifiedValue *= 2;
      if (m == FIELD_TYPE.TripleWord) modifiedValue *= 3;
      if (m == FIELD_TYPE.HalfWord) modifiedValue *= 0.5;
      // if (m == FIELD_TYPE.ZeroWord) modifiedValue *= 0;

      val += modifiedValue;
    });

    if (wordModifiers.includes(FIELD_TYPE.ZeroWord)) {
      val = 0;
    }

    if (fullLength) val += fullLengthBonus;
    pointValue += val;

    return pointValue;
  }

  function AwardPointsV2(word, fields) {
    var pointValue = 0;
    var val = 0;

    word.pieces.forEach((p) => {
      p.isWord = p.wordX == true || p.wordY == true ? true : false;
    });

    word.connections.forEach((cWord) => {
      if (cWord.wordDist < word.wordDist)
        if (cWord.valid == false) word.valid = false;
    });

    var isWord = word.valid;
    if (isWord === false) {
      word.pieces.forEach((p) => {
        p.isWord = false;
      });

      return pointValue;
    }

    var fullLength = word.pieces.length > 6 ? true : false;

    var wordModifiers = [];

    word.pieces.forEach((p) => {
      var halfL = false;
      var doubleL = false;
      var tripleL = false;
      var zeroL = false;
      // Check for special fields
      var x = p.x;
      var y = p.y;
      if (fields[x][y] === FIELD_TYPE.DoubleWord)
        wordModifiers.push(FIELD_TYPE.DoubleWord); //doubleW = true;
      if (fields[x][y] === FIELD_TYPE.TripleWord)
        wordModifiers.push(FIELD_TYPE.TripleWord); //;
      if (fields[x][y] === FIELD_TYPE.HalfWord)
        wordModifiers.push(FIELD_TYPE.HalfWord); //;
      if (fields[x][y] === FIELD_TYPE.ZeroWord)
        wordModifiers.push(FIELD_TYPE.ZeroWord); //;
      if (fields[x][y] === FIELD_TYPE.HalfLetter) halfL = true;
      if (fields[x][y] === FIELD_TYPE.DoubleLetter) doubleL = true;
      if (fields[x][y] === FIELD_TYPE.TripleLetter) tripleL = true;
      if (fields[x][y] === FIELD_TYPE.ZeroLetter) zeroL = true;

      fullLength = p.fullLength == true ? true : fullLength;
      game[p.x][p.y].fullWord = fullLength;

      // Check Pointvalue
      var letterValue = parseInt(p.value);
      letterValue *= halfL ? 0.5 : 1;
      letterValue *= doubleL ? 2 : 1;
      letterValue *= tripleL ? 3 : 1;
      val += letterValue;
    });

    // if (doubleW) val *= 2;
    // if (tripleW) val *= 3;
    var modifiedValue = 0;
    var sum = 0;

    wordModifiers.forEach((m) => {
      modifiedValue = val;
      if (m == FIELD_TYPE.DoubleWord) modifiedValue *= 2;
      if (m == FIELD_TYPE.TripleWord) modifiedValue *= 3;
      if (m == FIELD_TYPE.HalfWord) modifiedValue *= 0.5;

      sum += modifiedValue;
      console.log("Value " + val + " + SUM " + sum);
    });

    if (sum > 0) sum -= val; // Check this with half values

    val += sum;

    if (wordModifiers.includes(FIELD_TYPE.ZeroWord)) {
      val = 0;
    }

    if (fullLength) val += fullLengthBonus;
    pointValue += val;

    return pointValue;
  }

  function SetManhattenDist(piece) {
    let val = piece.manhattenDist;

    if (piece.prevX != null)
      if (piece.prevX.manhattenDist > val - 1) {
        piece.prevX.manhattenDist = val + 1;
        SetManhattenDist(piece.prevX);
      }

    if (piece.nextX != null)
      if (piece.nextX.manhattenDist > val - 1) {
        piece.nextX.manhattenDist = val + 1;
        SetManhattenDist(piece.nextX);
      }

    if (piece.prevY != null)
      if (piece.prevY.manhattenDist > val - 1) {
        piece.prevY.manhattenDist = val + 1;
        SetManhattenDist(piece.prevY);
      }

    if (piece.nextY != null)
      if (piece.nextY.manhattenDist > val - 1) {
        piece.nextY.manhattenDist = val + 1;
        SetManhattenDist(piece.nextY);
      }
  }

  const [isGameEnded, setIsGameEnded] = useState(false);

  function EndGame() {
    setIsGameEnded(true);
    currentGame.finished = true;
    currentGame.pieces = pieces;

    pruneSaveGame();

    dispatch({ type: "SET_TIME", payload: 0 });
  }

  // RENDERING
  return (
    <DndContext
      onDragEnd={handleDragEnd}
      modifiers={[restrictToWindowEdges]}
      sensors={sensors}
    >
      {started ? (
        <div className="canvas">
          <div className="playing-field">
            <Logo>{languageFlag}</Logo>
            <div className="meta">
              <div className="timer">
                <span>Time</span>
                {!infiniteMode ? (
                  <CountdownTimer
                    targetDate={initialTime}
                    active={!isGameEnded}
                    callback={EndGame}
                  ></CountdownTimer>
                ) : (
                  <span>∞</span>
                )}
              </div>
              <div className="points">
                <Unload>{timeLeft}</Unload>
                <ExitGame
                  endGameCallback={EndGame}
                  active={!isGameEnded}
                ></ExitGame>
              </div>
              <Points>{points}</Points>
            </div>

            <div className="board">
              {yCol.map((identifier, y) => (
                <div className="row" key={identifier}>
                  {xCon.map((id, x) => (
                    <Droppable
                      key={identifier + "-" + id}
                      id={identifier + "-" + id}
                      x={x}
                      y={y}
                      fieldType={specialFields[x][y]}
                    >
                      {/* {"x:" + x + ", y:" + y} */}
                      {pieces.map((piece, i) =>
                        piece.parent === identifier + "-" + id ? (
                          <Draggable
                            id={"draggable" + i}
                            state="dropped"
                            value={piece.value}
                            key={piece.letter + "-" + i}
                            letter={piece.letter}
                            parent={identifier + "-" + id}
                            index={i}
                            isword={+piece.isWord}
                            disabled={timeLeft <= 0 || isGameEnded}
                            manhattendist={piece.manhattenDist}
                            fulllength={+piece.fullWord}
                            fullgame={+piece.fullGame}
                          >
                            {piece.letter}
                          </Draggable>
                        ) : (
                          ""
                        )
                      )}
                    </Droppable>
                  ))}
                </div>
              ))}
            </div>

            <div className="relative">
              <div className="pieces-background">
                <div className="piece-container">
                  {pieces.map((piece, i) =>
                    piece.parent === null ? (
                      <Draggable
                        id={piece.letter + "-" + i}
                        state="not-dropped"
                        value={piece.value}
                        key={piece.letter + "-" + i}
                        letter={piece.letter}
                        parent={piece.parent}
                        index={i}
                        isword={+piece.isWord}
                        disabled={timeLeft <= 0 || isGameEnded}
                        manhattendist={piece.manhattenDist}
                      >
                        {piece.letter}
                      </Draggable>
                    ) : (
                      <span className="empty" key={"empty" + i}></span>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>

          {isGameEnded ? (
            <div className="endscreen">
              <div>Game ended. Your Score is {points}</div>
              <RWebShare
                data={{
                  text:
                    "I got " +
                    points +
                    " points in LexiJam " +
                    languageFlag +
                    "! (" +
                    timeDesc +
                    ")\n" +
                    shareDescription,
                  url: "",
                  title: "LexiJam",
                }}
                onClick={() =>
                  console.log(
                    "I got " +
                      points +
                      " points in LexiJam " +
                      languageFlag +
                      "! (" +
                      timeDesc +
                      ")\n" +
                      shareDescription
                  )
                }
              >
                <button>Share 🔗</button>
              </RWebShare>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="startscreen">
          <Logo>{languageFlag}</Logo>
          {sessionPlayed ? (
            <div>
              <p>
                You already played.
                <br />
                Next Chance is at {roundToNearestHour()}
              </p>

              <Link className="button" to="/">
                Back
              </Link>
            </div>
          ) : (
            <div>
              <Disclaimer />

              {/* <a onClick={() => toggleGame(true)} className="button m-bottom">
                Daily Challenge - experimental
              </a> */}
              <br />
              <a onClick={() => toggleGame(false)} className="button m-bottom">
                Start Game
              </a>

              <br />

              <Link className="button" to="/">
                {languageData.map(function (d) {
                  return d.backPrompt[language];
                })}
              </Link>
            </div>
          )}
        </div>
      )}
    </DndContext>
  );

  // function SetInfiniteMode(infinite){
  //   infiniteMode = infinite;
  // }

  function roundToNearestHour() {
    const now = new Date();
    const nextHour = new Date(now.getTime() + 60 * 60 * 1000); // Add one hour
    const hour = nextHour.getHours().toString().padStart(2, "0");
    const minutes = "00";

    return hour + ":" + minutes;

    // return roundedTime;
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    // if (!over) return;

    let id = over ? over.id : null;
    let x = over ? over.data.current.x : null;
    let y = over ? over.data.current.y : null;
    let md = over ? UNCONNECTED : Number.MAX_SAFE_INTEGER;

    if (over) {
      if (specialFields[x][y] == FIELD_TYPE.Blocked) return;

      for (var n = 0; n < pieces.length; n++) {
        if (pieces[n].parent == over.id) return;
      }
    }

    let i = active.data.current.index;
    gamePieces = [...pieces]; // copy pieces state
    gamePieces[i].parent = id;
    gamePieces[i].x = x;
    gamePieces[i].y = y;
    gamePieces[i].manhattenDist = md;
    setPieces(gamePieces);
    dispatch({ type: "SET_PIECES", payload: gamePieces });

    currentGame.pieces = gamePieces;

    pruneSaveGame();
  }

  function pruneSaveGame() {
    // Load savedGames from localStorage or initialize an empty array if it doesn't exist
    var tempSavedGame = localStorage.getItem("savedGames");

    if (tempSavedGame !== null) savedGames = parse(tempSavedGame);
    else savedGames = [];

    if (savedGames.length > 0) {
      // Remove null objects from the savedGames array
      savedGames = savedGames.filter((save) => save !== null);

      // Find the index of the savedGame with the same session as currentGame
      var indexToReplace = savedGames.findIndex(
        (save) => save.session === currentGame.session
      );

      // If a matching savedGame was found, replace it
      if (indexToReplace !== -1) {
        savedGames[indexToReplace] = currentGame;
      } else {
        // If no matching savedGame was found, add the currentGame
        savedGames.push(currentGame);
      }

      // Remove all games with the session ID of currentGame
      savedGames = savedGames.filter(
        (save) => save.session !== currentGame.session
      );
    }

    savedGames.push(currentGame);

    // Save the pru§ned array back to localStorage
    localStorage.setItem("savedGames", stringify(savedGames));
  }
}
