import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

const useCountdown = (targetDate, active, callback) => {
  const dispatch = useDispatch();
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    if (!active) return getReturnValues(0);
    
    
    // console.log(seconds);
    // dispatch({ type: "SET_TIMER", payload: seconds });
    
    const interval = setInterval(() => {
      var countDown = countDownDate - new Date().getTime();
      var seconds = Math.floor(countDown / 1000);
      
      if (seconds === 0) {
        localStorage.setItem("game", true);
        callback();
      }
      
      dispatch({ type: "SET_TIME", payload: seconds });
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  //   const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  //   const hours = Math.floor(
  //     (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //   );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [minutes, seconds];
};

export { useCountdown };
