import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import "./App.css";
import App from "./App";
import Layout from "./pages/Layout";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/index";
import dict_DE from "./words_DE_short.txt";
import dict_EN from "./words_EN_short.txt";
import Instructions from "./components/Instructions";
import Archive from "./components/Archive";
import Data from "./components/Data";
import { allOthers, allVowels } from "./constants/germanLetterSet";
import {
  allOthersEnglish,
  allVowelsEnglish,
} from "./constants/englishLetterSet";
import ReactGA from "react-ga4";


window.store = store;

ReactGA.initialize("G-TTKRN4WRJH");


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <React.StrictMode>
        <Data />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              path="/instructions"
              element={
                <Instructions />
              }
            ></Route>
            <Route
              path="/de"
              element={
                <App
                  dictionary={dict_DE}
                  language={"de"}
                  vowels={allVowels}
                  consonants={allOthers}
                />
              }
            ></Route>
            <Route
              path="/archive"
              element={
                <Archive />
              }
            ></Route>
            <Route
              path="/en"
              element={
                <App
                  dictionary={dict_EN}
                  language={"en"}
                  vowels={allVowelsEnglish}
                  consonants={allOthersEnglish}
                />
              }
            ></Route>
          </Route>
        </Routes>
      </React.StrictMode>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
