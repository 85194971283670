import React from "react";
import { useCountdown } from "./useCountdown";

const CountdownTimer = ({ targetDate, active, callback }) => {
  const [minutes, seconds] = useCountdown(targetDate, active, callback);

  if (minutes + seconds <= 0 || !active) {
    return (
    //   <div className="expired-notice">
    //     <span>Expired!!!</span>
    //     <p>Please select a future date and time.</p>
    //   </div>
    <div>
    {0 + ":" +0}
  </div>
    );
  } else {
    return (
      <div>
        {minutes + ":" +seconds}
      </div>
    );
  }
};

export { CountdownTimer };
