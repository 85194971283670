import React, { useState, useEffect } from "react";
import { Droppable } from "../components/Droppable";
import { Draggable } from "../components/Draggable";
import { FIELD_TYPE, fieldMap } from "../constants/fieldTypes";
import { Outlet, Link, useLocation } from "react-router-dom";
import Logo from "../components/Logo";
import { useSelector } from "react-redux";
import { parse, stringify, toJSON, fromJSON } from "flatted";
import { format, parseISO } from "date-fns";

let gamePieces = [];
let saveGames = [];

const Archive = () => {
  const languageData = useSelector((state) => state.languageData);
  const language = useSelector((state) => state.language);
  const [awake, setAwake] = useState("");

  const xCon = ["A", "B", "C", "D", "E", "F", "G"];
  const yCol = ["row1", "row2", "row3", "row4", "row5", "row6", "row7"];
  const [pieces, setPieces] = useState(gamePieces);
  const [savedGames, setGames] = useState(saveGames);

  // INIT / SETUP
  useEffect(() => {
    var tempSavedGame = localStorage.getItem("savedGames");
    var games = [];

    if (tempSavedGame !== null) games = parse(tempSavedGame);
    // else savedGames = [];

    let reversedSavedGames = [...games].reverse();

    setGames(reversedSavedGames);

    if (savedGames.length > 0) {
      setPieces(savedGames[0].pieces);
    }
  }, []);

  function GetSessionTitle(session) {
    // Remove the surrounding  quotes, if present
    const cleanedSession = session.replace(/^"|"$/g, "");

    // Split the session string by the first hyphen
    const hyphenIndex = cleanedSession.indexOf("-");
    if (hyphenIndex !== -1) {
      const language = cleanedSession.slice(0, hyphenIndex);

      // Extract the date and time substring
      const dateAndTime = cleanedSession.slice(hyphenIndex + 1);

      // Parse the date manually
      const dateComponents = dateAndTime.match(
        /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z/
      );
      if (dateComponents) {
        const year = parseInt(dateComponents[1]);
        const month = parseInt(dateComponents[2]) - 1; // Month is 0-indexed
        const day = parseInt(dateComponents[3]);
        const hours = parseInt(dateComponents[4]);
        const minutes = parseInt(dateComponents[5]);

        // Create a Date object
        const date = new Date(Date.UTC(year, month, day, hours, minutes));

        // Format the date as "dd.MM.yy, HH:mm"
        const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date
          .getFullYear()
          .toString()
          .slice(-2)}, ${String(date.getUTCHours()).padStart(2, "0")}:${String(
          date.getUTCMinutes()
        ).padStart(2, "0")}`;

        var languageFlag = language === "de" ? "🇩🇪" : "🇬🇧";
        return languageFlag + " " + formattedDate;
      }
    } else {
      // Handle the case when the session string format is not as expected
      return "Custom Session";
    }
  }

  function GetSymbol(str) {
    const inputString = str;
    const regex = /\(([^)]+)\)/;
    const match = regex.exec(inputString);

    if (match && match[1]) {
      var extractedText = match[1];
      // console.log(extractedText); // This will print "letter" to the console
      return extractedText;
    } else {
      // console.log("No text found in brackets");
    }
  }

  return (
    <div className="startscreen scroll-area archive-page">
      <Logo />

      <Link className="button m-top" to="/">
        {languageData.map(function (d) {
          return d.backPrompt[language];
        })}
      </Link>

      <div className="playing-field archive">
        {savedGames.map((game, index) => (
          <li key={index}>
            {/* {console.log(game)} */}
            <div className="divider m-top"></div>
            <p>
              <small>Session:</small>
              <br /> {GetSessionTitle(game.session)} - Points:{" "}
              {game.points === undefined ? "?" : game.points}
            </p>
            {/* <p>Time: {game.time}</p>
          <p>Time Left: {game.timeLeft}</p>
          <p>Finished: {game.finished ? "Yes" : "No"}</p> */}
            <div className="board">
              {yCol.map((identifier, y) => (
                <div className="row" key={identifier}>
                  {xCon.map((id, x) => (
                    <Droppable
                      key={identifier + "-" + id}
                      id={identifier + "-" + id}
                      x={x}
                      y={y}
                      fieldType={
                        game.fields === undefined ||
                        game.fields[x] === undefined
                          ? ""
                          : game.fields[x].length === 0
                          ? ""
                          : game.fields[x][y] !== "" || game.fields[x][y] !== undefined
                          ? GetSymbol(game.fields[x][y]) !== undefined ? fieldMap[GetSymbol(game.fields[x][y])] : ""
                          : ""
                      }
                    >
                      {game.pieces.map((piece, i) =>
                        piece.parent === identifier + "-" + id ? (
                          <Draggable
                            id={"draggable" + i}
                            state="dropped"
                            value={piece.value}
                            key={piece.letter + "-" + i}
                            letter={piece.letter}
                            parent={identifier + "-" + id}
                            index={i}
                            isword={+piece.isWord}
                            disabled={game.timeLeft <= 0 || game.isGameEnded}
                            manhattendist={piece.manhattenDist}
                            fulllength={+piece.fullWord}
                            fullgame={+piece.fullGame}
                          >
                            {piece.letter}
                          </Draggable>
                        ) : (
                          ""
                        )
                      )}
                    </Droppable>
                  ))}
                </div>
              ))}
            </div>

            <div className="relative">
              <small className="white m-bottom m-top block">
                Unused pieces:
              </small>

              <div className="piece-container">
                {game.pieces.map((piece, i) =>
                  piece.parent === null ? (
                    <Draggable
                      id={piece.letter + "-" + i}
                      state="not-dropped"
                      value={piece.value}
                      key={piece.letter + "-" + i}
                      letter={piece.letter}
                      parent={piece.parent}
                      index={i}
                      isword={+piece.isWord}
                      disabled={game.timeLeft <= 0 || game.isGameEnded}
                      manhattendist={piece.manhattenDist}
                    >
                      {piece.letter}
                    </Draggable>
                  ) : (
                    <span className="empty" key={"empty" + i}></span>
                  )
                )}
              </div>
            </div>
          </li>
        ))}
      </div>

      <Link className="button m-top" to="/">
        {languageData.map(function (d) {
          return d.backPrompt[language];
        })}
      </Link>
    </div>
  );
};

export default Archive;
