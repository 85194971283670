import { createStore } from "redux";
import {
  saveTemplate,
} from "../constants/templates";

const initialState = {
  time: 90,
  timer: 0,
  pieces: [],
  currentSession: "",
  languageData: [],
  language: "",
};

const reducer = (state = initialState, action) => {
  if (action.type === "SET_TIME") {

    return Object.assign({}, state, {
      time: action.payload
    });
  }
  
  if (action.type === "SET_TIMER") {

    var newVal = action.payload;
    
    return Object.assign({}, state, {
      timer: newVal
    });
  }
  
  if (action.type === "SET_PIECES") {

    var newVal = action.payload;
    
    return Object.assign({}, state, {
      pieces: [...newVal]
    });
  }

  if (action.type === "SET_GAME") {

    var newVal = action.payload;
    
    return Object.assign({}, state, {
      currentSession: newVal
    });
  }

  if (action.type === "SET_LANGUAGE_DATA") {

    var newVal = action.payload;
    
    return Object.assign({}, state, {
      languageData: newVal
    });
  }
  if (action.type === "SET_LANGUAGE") {

    var newVal = action.payload;
    
    return Object.assign({}, state, {
      language: newVal
    });
  }

  return state;
};

const store = createStore(reducer);

export default store;
