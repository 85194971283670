import React, { useState } from "react";
import { Droppable } from "../components/Droppable";
import { Draggable } from "../components/Draggable";
import { FIELD_TYPE } from "../constants/fieldTypes";
import { Outlet, Link, useLocation } from "react-router-dom";
import Logo from "../components/Logo";
import { useSelector } from "react-redux";

const ExitGame = ({ active }) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const languageData = useSelector((state) => state.languageData);
  const language = useSelector((state) => state.language);

  const handleOpenClick = () => {
    setIsOverlayOpen(true);
  };

  const handleCloseOverlay = () => {
    setIsOverlayOpen(false);
  };

  return (
    <div className="startscreen scroll-area">
      <Logo />
      <div className="exit-overlay">
        {/* <a className="button m-bottom" onClick={handleOpenClick}>
        How to Play
      </a>
      {isOverlayOpen && ( */}
        <div className="">
          {/* <a className="button" onClick={handleCloseOverlay}>
            Close
          </a> */}
          <p className="instructions">
            {/* LexiJam gives you 14 letters and 90 seconds to form as many words a
            possible. Drag and drop them on the board horizontally or vertically
            but keep them connected. <br /> <br /> The goal: To get as many
            points as possible. And don't forget to cover the starting field! */}
            {languageData.map(function (d) {
              return d.instructions[language];
            })}
          </p>

          <p className="instructions">
            {/* Bonuses
            <br />
            +10pts for a word with seven letters
            <br />
            +25pts for using al 14 letters */}

            {languageData.map(function (d) {
              return d.bonuses[language];
            })}
          </p>

          <div className="flex-container">
            <div className="field-item">
              <Draggable
                id={"draggable"}
                state="dropped"
                value={2}
                key={"L"}
                letter={"L"}
                isword={+1}
              >
                {"L"}
              </Draggable>
              <br />
              {/* This is a Letter with it's value. */}
              {languageData.map(function (d) {
                return d.letter[language];
              })}
            </div>
            <div className="field-item">
              <Droppable fieldType={FIELD_TYPE.Start} id={0}></Droppable>
              <br />
              {/*  This is the Starting Field. */}
              {languageData.map(function (d) {
                return d.startingField[language];
              })}
            </div>
            <div className="field-item">
              <Droppable fieldType={FIELD_TYPE.None} id={0}></Droppable> <br />{" "}
              {/* This is a normal Field */}
              {languageData.map(function (d) {
                return d.normalField[language];
              })}
            </div>
            <div className="field-item">
              <Droppable fieldType={FIELD_TYPE.Blocked} id={0}></Droppable>{" "}
              <br />
              {/* This field is blocked. You can't play on it. */}
              {languageData.map(function (d) {
                return d.blockedField[language];
              })}
            </div>
          </div>
          <div className="flex-container">
            <div className="field-item">
              <Droppable fieldType={FIELD_TYPE.DoubleLetter} id={0}></Droppable>{" "}
              <br />
              {/* Letters on this field are worth double the ponts. */}
              {languageData.map(function (d) {
                return d.twoL[language];
              })}
            </div>
            <div className="field-item">
              <Droppable fieldType={FIELD_TYPE.TripleLetter} id={0}></Droppable>{" "}
              <br />
              {/* Letters on this field are worth triple the ponts. */}
              {languageData.map(function (d) {
                return d.threeL[language];
              })}
            </div>
            <div className="field-item">
              <Droppable fieldType={FIELD_TYPE.HalfLetter} id={0}></Droppable>{" "}
              <br />
              {/* Letters on this field are only worth half. */}
              {languageData.map(function (d) {
                return d.halfL[language];
              })}
            </div>
            <div className="field-item white">
              <Droppable fieldType={FIELD_TYPE.ZeroLetter} id={0}></Droppable>{" "}
              <br />
              {/* Letters on this field are worth no points. */}
              {languageData.map(function (d) {
                return d.zeroL[language];
              })}
            </div>
          </div>
          <div className="flex-container">
            <div className="field-item">
              <Droppable fieldType={FIELD_TYPE.DoubleWord} id={0}></Droppable>{" "}
              <br />
              {/* Words that cover this field are worth double. */}
              {languageData.map(function (d) {
                return d.twoW[language];
              })}
            </div>
            <div className="field-item">
              <Droppable fieldType={FIELD_TYPE.TripleWord} id={0}></Droppable>{" "}
              <br />
              {/* Words that cover this field are worth triple. */}
              {languageData.map(function (d) {
                return d.threeW[language];
              })}
            </div>
            <div className="field-item">
              <Droppable fieldType={FIELD_TYPE.HalfWord} id={0}></Droppable>{" "}
              <br />
              {/* Words that cover this field are worth half. */}
              {languageData.map(function (d) {
                return d.halfW[language];
              })}
            </div>
            <div className="field-item white">
              <Droppable fieldType={FIELD_TYPE.ZeroWord} id={0}></Droppable>{" "}
              <br />
              {/* Words that cover this field are worth nothing. */}
              {languageData.map(function (d) {
                return d.zeroW[language];
              })}
            </div>
          </div>

          <Link className="button m-top" to="/">
            {languageData.map(function (d) {
              return d.backPrompt[language];
            })}
          </Link>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default ExitGame;
