import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { FIELD_TYPE } from "../constants/fieldTypes";

export function Droppable(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
    drop: props.drop,
    data: {
      x: props.x,
      y: props.y,
    },
  });
  const style = {
    backgroundColor:
      isOver && props.fieldType !== FIELD_TYPE.Blocked ? "green" : undefined,
  };

  function fieldName(fType) {
    var fieldString = "";

    switch (fType) {
      case FIELD_TYPE.Blocked:
        fieldString = "";
        break;
      case FIELD_TYPE.HalfLetter:
        fieldString = "½L";
        break;
      case FIELD_TYPE.HalfWord:
        fieldString = "½W";
        break;
      case FIELD_TYPE.DoubleLetter:
        fieldString = "2L";
        break;
      case FIELD_TYPE.DoubleWord:
        fieldString = "2W";
        break;
      case FIELD_TYPE.TripleLetter:
        fieldString = "3L";
        break;
      case FIELD_TYPE.TripleWord:
        fieldString = "3W";
        break;
      case FIELD_TYPE.ZeroLetter:
        fieldString = "0L";
        break;
      case FIELD_TYPE.ZeroWord:
        fieldString = "0W";
        break;
      case FIELD_TYPE.Start:
        fieldString = "***";
        break;
    }

    return fieldString;
  }

  function fieldClass(fType) {
    var fieldString = "field ";

    switch (fType) {
      case FIELD_TYPE.Blocked:
        fieldString += "blocked";
        break;
      case FIELD_TYPE.HalfLetter:
        fieldString += "hl";
        break;
      case FIELD_TYPE.HalfWord:
        fieldString += "hw";
        break;
      case FIELD_TYPE.DoubleLetter:
        fieldString += "dl";
        break;
      case FIELD_TYPE.DoubleWord:
        fieldString += "dw";
        break;
      case FIELD_TYPE.TripleLetter:
        fieldString += "tl";
        break;
      case FIELD_TYPE.TripleWord:
        fieldString += "tw";
        break;
      case FIELD_TYPE.ZeroLetter:
        fieldString += "zl";
        break;
      case FIELD_TYPE.ZeroWord:
        fieldString += "zw";
        break;
      case FIELD_TYPE.Start:
        fieldString += "start";
        break;
    }

    return fieldString;
  }

  return (
    <div className={fieldClass(props.fieldType)} ref={setNodeRef} style={style}>
      <span className="field-type">{fieldName(props.fieldType)}</span>
      {props.children}
    </div>
  );
}
